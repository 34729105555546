<template>
  <div class="basucInfoPage">
    <div class="top">
      <el-button type="primary" size="medium" @click="save('basicInfoForm')">保存</el-button>
    </div>
    <!-- 表单区域 -->
    <el-form :model="basicInfoForm" :rules="vehicleRule" ref="basicInfoForm" label-width="150px">
      <el-form-item label="法人姓名" prop="legalPersonIName">
        <el-input v-model="basicInfoForm.legalPersonIName" placeholder="请输入法人姓名"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证号" prop="legalPersonIIDCard">
        <el-input v-model="basicInfoForm.legalPersonIIDCard" placeholder="请输入法人身份证号"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证有效期" prop="legalPersonlIDCardDate">
        <el-date-picker v-model="basicInfoForm.legalPersonlIDCardDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择法人身份证有效期"></el-date-picker>
      </el-form-item>
      <el-form-item label="法人手机号码" prop="legalPersonlPhone">
        <el-input v-model="basicInfoForm.legalPersonlPhone" placeholder="请输入法人手机号码"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="enterpriseFullName">
        <el-input v-model="basicInfoForm.enterpriseFullName" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
        <el-input v-model="basicInfoForm.enterpriseCreditCode" placeholder="请输入统一社会信用代码"></el-input>
      </el-form-item>
      <el-form-item label="营业执照住所" prop="enterpriseAddress">
        <el-input v-model="basicInfoForm.enterpriseAddress" placeholder="请输入营业执照住所"></el-input>
      </el-form-item>
      <el-form-item label="营业执照注册日期" prop="enterpriseRegisterDate">
        <el-date-picker v-model="basicInfoForm.enterpriseRegisterDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择营业执照注册日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="营业执照有效日期" prop="enterpriseEffectiveDate">
        <el-date-picker v-model="basicInfoForm.enterpriseEffectiveDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择营业执照有效日期"></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import verify from '@/utils/verify.js'
import { saveGoodsOwnerInfo, getGoodsOwnerInfo } from '@/api/goodsOwner/goodsOwnerManage/basicInfo'
export default {
  data() {
    return {
      basicInfoForm: {
        userID: 0,
        enterpriseContactsName: '', //企业联系人
        enterpriseContactsPhone: '', //企业联系人电话
        enterpriseCreditCode: '', //统一社会信用代码
        enterpriseFullName: '', //公司全称：发票抬头
        legalPersonIName: '', //法人代表
        legalPersonIIDCard: '', //法人身份证号
        legalPersonlPhone: '', //法人电话
        areaId: '', //地区标识
        placeAttribution: '', //所属地区
        enterpriseAddress: '', //营业执照住所
        enterpriseRegisterDate: '', //营业执照注册日期
        enterpriseEffectiveDate: '', //营业执照有效日期
        legalPersonlIDCardDate: '', //法人身份证有效日期
        rtBusinessLicense: '', //道路运输经营许可证编号
        defaultRate: '', //默认费率：国家规定的9个点
        bankerName: '', //开户银行:发票抬头（专票需要)
        bankerNumber: '', //银行账号：发票抬头（专票需要）
        status: 0, //企业状态:0未校验1校验通过2校验未通过
        remark: '', //错误原因：未通过原因
        invoiceType: '', //发票类型
        defaultRate1: '', //1%或3%票据的默认费率
      },
      vehicleRule: {
        legalPersonIName: [{ required: true, message: '请输入法人姓名', trigger: 'change' }],
        legalPersonIIDCard: [{ required: true, message: '请输入法人身份证号', trigger: 'change' }],
        legalPersonlIDCardDate: [{ required: true, message: '请选择法人身份证有效期', trigger: 'change' }],
        legalPersonlPhone: [{ required: true, message: '请输入法人手机号码', trigger: 'change' }],
        enterpriseFullName: [{ required: true, message: '请输入企业名称', trigger: 'change' }],
        enterpriseCreditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'change' }],
        enterpriseAddress: [{ required: true, message: '请输入营业执照住所', trigger: 'change' }],
        enterpriseRegisterDate: [{ required: true, message: '请选择营业执照注册日期', trigger: 'change' }],
        enterpriseEffectiveDate: [{ required: true, message: '请选择营业执照有效日期', trigger: 'change' }],
      },
    }
  },
  methods: {
    ...mapMutations(["setFullInfo"]),
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userID: this.basicInfoForm.userID, //
            enterpriseContactsName: this.basicInfoForm.enterpriseContactsName,
            enterpriseContactsPhone: this.basicInfoForm.enterpriseContactsPhone,
            enterpriseCreditCode: this.basicInfoForm.enterpriseCreditCode,
            enterpriseFullName: this.basicInfoForm.enterpriseFullName,
            legalPersonIName: this.basicInfoForm.legalPersonIName,
            legalPersonIIDCard: this.basicInfoForm.legalPersonIIDCard,
            legalPersonlPhone: this.basicInfoForm.legalPersonlPhone,
            areaId: 0,
            placeAttribution: this.basicInfoForm.placeAttribution,
            enterpriseAddress: this.basicInfoForm.enterpriseAddress,
            enterpriseRegisterDate: this.basicInfoForm.enterpriseRegisterDate,
            enterpriseEffectiveDate: this.basicInfoForm.enterpriseEffectiveDate,
            legalPersonlIDCardDate: this.basicInfoForm.legalPersonlIDCardDate,
            rtBusinessLicense: this.basicInfoForm.rtBusinessLicense,
            defaultRate: this.basicInfoForm.defaultRate,
            bankerName: this.basicInfoForm.bankerName,
            bankerNumber: this.basicInfoForm.bankerNumber,
            status: this.basicInfoForm.status,
            remark: this.basicInfoForm.remark,
            invoiceType: this.basicInfoForm.invoiceType,
            defaultRate1: this.basicInfoForm.defaultRate1,
          }
          if(verify._isCardNo(params.legalPersonIIDCard) == false){
            this.$message({
              showClose: true,
              message: '法人身份证号输入有误，请重新输入。',
              type: 'error'
            });
            return
          }
          if(verify._phone(params.legalPersonlPhone) == false){
            this.$message({
              showClose: true,
              message: '法人手机号码输入有误，请重新输入。',
              type: 'error'
            });
            return
          }
          saveGoodsOwnerInfo(params).then(res => {
            this.setFullInfo(1)
          })
        }
      })
    },
    //获取基本信息
    getGoodsOwnerInfo() {
      getGoodsOwnerInfo({}).then(res => {
        if(res.enterPrise != null){
          this.basicInfoForm.legalPersonIName = res.enterPrise.LegalPersonIName
          this.basicInfoForm.legalPersonIIDCard = res.enterPrise.LegalPersonIIDCard
          this.basicInfoForm.legalPersonlIDCardDate = res.enterPrise.LegalPersonlIDCardDate
          this.basicInfoForm.legalPersonlPhone = res.enterPrise.LegalPersonlPhone
          this.basicInfoForm.enterpriseFullName = res.enterPrise.EnterpriseFullName
          this.basicInfoForm.enterpriseCreditCode = res.enterPrise.EnterpriseCreditCode
          this.basicInfoForm.enterpriseAddress = res.enterPrise.EnterpriseAddress
          this.basicInfoForm.enterpriseRegisterDate = res.enterPrise.EnterpriseRegisterDate
          this.basicInfoForm.enterpriseEffectiveDate = res.enterPrise.EnterpriseEffectiveDate
          this.setFullInfo(1)
        }else{
          this.setFullInfo(0)
        }
      })
    }
  },
  created() {
    this.getGoodsOwnerInfo()
  }
}
</script>

<style lang="scss" scoped>
.basucInfoPage {
  .top {
    margin-bottom: 30px;
  }
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 400px;
    }
    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }
}
</style>